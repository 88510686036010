import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="SB9 new law for ADU: from R1 (Single family home) to 2 of R2: 4X entitlements!" />
      <h1 className="app__header-h1"> ADU: Pre-approval + Prefab + 100% certified+$40,000 grant!</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>$40,000 government grant.</p>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Pre-approved ADU plan will save you months and $$$! </p>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Pre-fab will turn home building into factory engineering: quality, accuracy and efficiency. </p>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>100% certified, save head aches and time from inspection.</p>
      
      <button type="button" className="custom__button">Explore Options</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
