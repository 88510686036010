import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.Adu} alt="A_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Our Mission</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Innovation for house crisis!</p>
        <p className="p__opensans">Marketplace for streamlined services! </p>
        <p className="p__opensans">Trust upon transparency!</p>

        <button type="button" className="custom__button">Know More</button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our Focus</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">AI and big data to connect</p>
        <p className="p__opensans">Pre-approved plan to save time!</p>
        <p className="p__opensans">Pre-fab for fast high quality green building!</p>
        <button type="button" className="custom__button">Solutions</button>
      </div>
    </div>
  </div>
);

export default AboutUs;
