import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Builder.css';

const Builder = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.builder} alt="builder_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Builder's word" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">city style over suburban will be the trend for people to stay connected!</p>
          <p className="p__opensans"> Home improvement market needs high tech and transparency! </p>
        </div>
        
        
      </div>

      <div className="app__chef-sign">
        <p>Jim Xiao</p>
        <p className="p__opensans">: California State Licensed # 1049430 Builder & Founder of Mise.ai</p>
        <img src={images.sign} alt="sign_image" />
      </div>
    </div>
  </div>
);

export default Builder;
