import images from './images';

const wines = [
  {
    title: 'ADU fast track',
    price: '$350/sf ',
    tags: ' ~2  months or sooner',
  },
  {
    title: 'TPO Roofing',
    price: '$11/sf',
    tags: '1 week',
  },
  {
    title: 'Level 5 finishing',
    price: '$10/sf',
    tags: '2-3 weeks',
  },


];

const cocktails = [
  {
    title: 'paint',
    price: '$5/sf',
    tags: '1-2weeks',
  },
  {
    title: 'Smart garden',
    price: '$3/sf credit ',
    tags: 'Save 8000 gal water a month',
  },
  {
    title: 'fence',
    price: '$50/ft',
    tags: '1-2weeks',
  },

];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Palo Alto New Construction',
    subtitle: 'Green building tier 2, title 24.',
  },
  {
    imgUrl: images.award01,
    title: 'Palo Alto New Construction ADU',
    subtitle: 'Green building tier 2, Green Halo, title 24.',
  },
  {
    imgUrl: images.award05,
    title: 'AAA Finishing quality inside out',
    subtitle: 'level 5 finishing of interior wall.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Builder',
    subtitle: '4 layers color stucco smmoth finishing.',
  },
];

export default { wines, cocktails, awards };
